<template>

  <div>
    <div class="d-flex justify-content-center mb-1">
      <b-spinner variant="success" span="Spinning" block v-if="loadingSpinner"
        style="width: 3rem; height: 3rem"></b-spinner>
    </div>
    <section class="invoice-add-wrapper" v-if="!loadingSpinner">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                <!-- Header: Left Content -->
                <Logo />

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">Purchase Order</h4>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title"> Date: </span>
                    <flat-pickr
                      v-model="issuedDate"
                      :config="config.wrap"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title"> Due Date: </span>
                    <flat-pickr v-model="dueDate" :config="configDue.wrap" class="form-control invoice-edit-input" />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="6" xl="6" class="mb-lg-1">
                  <h6 class="mb-2">Choose Supplier:</h6>

                  <b-form-select v-model="supplierId" :options="optionsCustomer" @change="supplierSelected(supplierId)" />

                  <!-- Selected Client -->
                  <div v-if="whenSelected !== null" class="mt-1">
                    <b-card-text class="mb-25">
                      {{ whenSelected.name }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ whenSelected.email }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ whenSelected.address }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ whenSelected.phoneNumber }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ whenSelected.contactPerson }}
                    </b-card-text>
                  </div>
                </b-col>
                <b-col cols="6" xl="6" class="mb-lg-1">
                  <h6 class="mb-2">Choose Warehouse:</h6>

                  <b-form-select v-model="warehouseId" :options="optionsWarehouse" />
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding ">
              <div ref="form" class="repeater-form">
                <b-row ref="row" class="pb-2">
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="3"> Item </b-col>
                        <b-col cols="12" lg="3"> Unit (Rs) </b-col>
                        <b-col cols="12" lg="3"> Qty </b-col>
                        <b-col cols="12" lg="3"> Total Price (Rs)</b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="3">
                          <span class="d-inline d-lg-none">Item</span>
                          {{ productID.name }}
                        </b-col>
                        <b-col cols="12" lg="3">
                          <span class="d-inline d-lg-none">Unit Price</span>
                          <b-form-input v-model="productID.purchasePrice" type="number" class="mb-2" readonly />
                        </b-col>
                        <b-col cols="12" lg="3">
                          <span class="d-inline d-lg-none">Qty</span>
                          <b-form-input v-model="quantity" type="number" class="mb-2" min="1"
                            invalid-feedback="Quantity must be 1 or more" @input="validateQuantity" required />
                          <div v-if="quantityError" class="invalid-feedback">{{ quantityError }}</div>
                        </b-col>
                        <b-col cols="12" lg="3">
                          <span class="d-inline d-lg-none">Total Price</span>
                          <p class="mb-1">Rs {{ computedPrice }}</p>
                        </b-col>
                        <b-col cols="12" lg="5">
                          <span class="d-inline d-lg-none">Description</span>
                          <b-form-textarea v-model="productID.description" class="mb-2 mb-lg-0" />
                        </b-col>
                      </b-row>

                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary"
                @click="addNewItemInItemForm">
                Add Item
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col cols="12" md="6" class="mt-md-0 mt-3 d-flex align-items-center" order="2" order-md="1">
                  <span for="invoice-data-sales-person" class="text-nowrap mr-50">Issuer:</span>
                  <b-form-select v-model="salesPersonId" :options="optionsSalesPerson" />
                </b-col>

                <!-- Col: Total -->
                <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                  <div class="invoice-total-wrapper">

                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total:</p>
                      <p class="invoice-total-amount">Rs {{ computedPrice }}</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea v-model="note" />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Print -->
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" block @click="addSalesOrder()"
            :disabled="loadingButton" :style="loadingButton ? { cursor: 'not-allowed' } : {}">
            <b-spinner small v-if="loading"> </b-spinner>
            Save
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
  </div>

</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

import { mapActions, mapGetters } from "vuex";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { debounce } from 'lodash';
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormSelect,
    BCardText,
    BForm,
    BFormGroup,
    BSpinner,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      optionsCustomer: [],
      loadingSpinner: false,
      quantity: this.$route.params.quantity,
      whenSelected: [],
      optionsWarehouse: [],
      warehouseId: "",

      id: this.$route.params.id,
      optionsProduct: [],
      optionsSalesPerson: [],
      salesPersonId: "",
      config: {
        wrap: {
          wrap: true,

          minDate: new Date(),
        },
      },
      configDue: {
        wrap: {
          wrap: true,

          minDate: new Date(),
        },
      },
      leadClientId: "",
      orderType: "",
      dueDate: "",
      issuedDate: new Date(),
      note: "",
      subTotal: 0,
      taxOptions: [
        { value: 0, text: "0%" },
        { value: 1, text: "15%" },
      ],
      tax: 0,
      supplierId: null,
      ///order type
      optionsOrderType: [
        { value: null, text: "Please select an option" },
        { value: 0, text: "Fixed Order" },
        { value: 1, text: "Variable Order" },
        { value: 2, text: "OneOff Order" },
      ],
    };
  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight();
  },
  computed: {
    computedPrice() {
      let total = 0;
      total = this.quantity * this.productID.purchasePrice;
      return total.toFixed(2);
    },
    ...mapGetters("supplierModule", {
      SupplierList: "suppliers",
      supplier: "supplierId",
      loading: "loading",
    }),
    ...mapGetters("purchaseOrderModule", {
      loading: "loading",
    }),
    ...mapGetters("warehouseModule", {
      warehouses: "warehouse",
      warehouseLoading: "loading",
    }),
    ...mapGetters("productModule", {
      ProductList: "product",
      productID: "productID",
      loading: "loading",
    }),
    ...mapGetters("rawmaterialModule", {
      RawMaterialList: "rawmaterials",
      rawmaterialsID: "rawmaterialsID",
      loading: "loading",
    }),
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    ...mapActions("warehouseModule", ["getWarehouseListAction"]),
    ...mapActions("supplierModule", ["getSupplierListAction", "getSupplierByIDAction"]),
    ...mapActions("productModule", ["getProductListAction", "getProductIDAction"]),
    ...mapActions("rawmaterialModule", ["getRawMaterialListAction", "getRawMaterialListByIdAction"]),
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    ...mapActions("purchaseOrderModule", ["addPurchaseOrdersAction"]),

    validateQuantity() {
      if (this.quantity < 1) {
        this.quantityError = 'Quantity must be 1 or more'
      } else {
        this.quantityError = ''
      }
    },
    addNewItemInItemForm() {
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)));

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    success() {
      this.$swal({
        title: "PurchaseOrder!",
        text: "You have successfully added the Purchase Order!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then(this.$router.push({ name: "purchaseorders-list" }));
    },
    // error
    error() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },


    
    addSalesOrder: debounce(function() {
      this.loadingButton = true;
      let payload = {
        status: 0,
        supplierId: this.supplierId,
        issuedDate: this.issuedDate,
        salesPersonId: this.salesPersonId,
        paymentMethod: "string",
        dueDate: this.dueDate,
        subTotal: this.computedPrice,
        discount: 0,
        tax: 0,
        warehouseId: this.warehouseId,
        total: this.computedPrice,
        note: this.notes,
        purchaseOrderItems: [
          {
            itemId: this.productID.id,
            isActive: true,
            cost: this.productID.purchasePrice,
            quantity: this.quantity,
            price: this.productID.purchasePrice,
            discount: 0,
            description: this.productID.description
          }
        ],
      };
      this.addPurchaseOrdersAction(payload)
        .then(() => {
          this.loadingButton = false;
          this.success();
        })
        .catch((error) => {
          this.loadingButton = false;
          this.error(error);
        });
    }, 300),

    async supplierSelected(id) {
      await this.getSupplierByIDAction(id).then(() => {
        this.whenSelected = this.supplier;
      });
    },
  },
  async mounted() {
    this.loadingSpinner = true; // Set to true when the component is mounted
    setTimeout(() => {
      this.loadingSpinner = false; // Set to false after 4 seconds
    }, 6000);

    this.whenSelected = null;
    await this.getSupplierListAction().then(() => {
      this.SupplierList.map((v, i) =>
        this.optionsCustomer.push({
          value: v.id,
          text: `${v.name}`,
        })
      );
    });
    await this.getWarehouseListAction().then(() => {
      this.warehouses.map((v, i) =>
        this.optionsWarehouse.push({
          value: v.id,
          text: `${v.name}`,
        })
      );
    });
    await this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.map((v, i) =>
        this.optionsSalesPerson.push({
          value: v.id,
          text: `${v.firstName}` + " " + `${v.lastName}`,
        })
      );
    });
    await this.getProductIDAction(this.id).then(() => {
     console.log("This is not a product")
    }).catch(() => {
      this.getRawMaterialListByIdAction(this.id).then(() => {
       this.productID = this.rawmaterialsID
      });
    });
  },
  setup() {
    const itemFormBlankItem = {
      item: null,
      value: null,
      cost: 0,
      purchase: 0,
      qty: 0,
      tax: 0,
      profit: 0,
      description: "",
    };

    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: "",
      note:
        "It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!",
      paymentMethod: null,
    });

    const updateItemForm = (index, val) => {
      const { cost, qty, description, purchase, tax, profit, value } = val;
      invoiceData.value.items[index].cost = cost;
      invoiceData.value.items[index].qty = qty;
      invoiceData.value.items[index].value = value;
      invoiceData.value.items[index].tax = tax;
      invoiceData.value.items[index].profit = profit;
      invoiceData.value.items[index].purchase = purchase;
      invoiceData.value.items[index].description = description;
    };

    return {
      invoiceData,
      updateItemForm,
      itemFormBlankItem,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

#vs7__listbox {
  z-index: -1;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
